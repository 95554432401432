import React from "react";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <Helmet>
      <meta httpEquiv={"refresh"} content={"0;URL=/not-found/"} />
    </Helmet>
  );
};
